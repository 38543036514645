import { Consultation } from 'src/communication/hasura/generated/graphql';
import { useGetPatientFromPartnerApi } from 'src/communication/hasura/query';
import { PatientData } from 'src/ui/components/PatientForm/types';

type GetPatient = {
  id: string;
  patient: PatientData | undefined;
  lastConsultation: Consultation | undefined;
};

type Hook = {
  getPatientPartner: (partnerName: string | undefined) => Promise<GetPatient>;
};

export const useGetPatientPartner = (): Hook => {
  const { getPatientFromPartnerApi } = useGetPatientFromPartnerApi();

  const getPatientPartner = async (patientPartnerId: string | undefined): Promise<GetPatient> => {
    if (!patientPartnerId)
      throw new Error('Patient partner not found', {
        cause: {
          userMsg: 'patientNotFound',
        },
      });

    const { data: patientPartnerData } = await getPatientFromPartnerApi(patientPartnerId);
    const patientPartner = patientPartnerData?.getPatientFromApi;
    if (!patientPartner)
      throw new Error('Patient partner not found', {
        cause: {
          userMsg: 'patientNotFound',
        },
      });
    return {
      id: patientPartner.id,
      patient: {
        ...patientPartner.patient,
        birthdate: patientPartner.patient?.birthdate
          ? new Date(patientPartner.patient?.birthdate)
          : undefined,
      },
      lastConsultation: patientPartner.lastConsultation,
    };
  };

  return { getPatientPartner };
};
