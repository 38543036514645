import { Tooth as ToothType } from '@allisone/react-components';

import { ToothMl } from 'src/common/types';

export const buildTooth = (teeth: ToothMl[]): ToothType[] =>
  teeth
    ?.filter(({ detected_as_missing, missing_tooth }) => {
      // The ML returns all possible teeth, even the one missing AND not detected as missing
      // These teeth (often x8 position) should not be consider in our xray tool
      const unrelevantTeeth = !detected_as_missing && missing_tooth;
      return !unrelevantTeeth;
    })
    .map((tooth, idx) => ({
      id: idx,
      elementType: tooth.element_type,
      missingTooth: tooth.missing_tooth || false,
      detectedAsMissing: tooth.detected_as_missing || false,
      detectionPoly: tooth.detection_poly,
      detectionBoxes: tooth.detection_boxes,
      detectionScores: tooth.detection_scores,
      detectionClasses: tooth.detection_classes,
      elementTypeFull: tooth.element_type_full,
      editByUser: tooth.edit_by_user || false,
      corner: tooth.corner,
      xCenter: tooth.x_center,
      yCenter: tooth.y_center,
      includedTooth: tooth.included_tooth || false,
    }));
