import { ToothNumberingSystem } from 'src/common/enums/ToothNumberingSystems';
import { Xray } from 'src/common/types';
import { convertToothNumberFDItoUNS } from 'src/controller/utils/dentistry/convertToothNumber';

export const convertXrayToothToUNSNumerotation = (
  xray: Xray,
  toothNumberingSystem: ToothNumberingSystem
): Xray => {
  if (toothNumberingSystem !== ToothNumberingSystem.UNS) return xray;

  return {
    ...xray,
    name: xray?.name ? xray?.name.map((label) => convertToothNumberFDItoUNS(label)) : [],
    anatomies: xray?.anatomies.map((anatomy) => ({
      ...anatomy,
      onTooth: anatomy.onTooth.map((tooth) => convertToothNumberFDItoUNS(tooth)),
    })),
    elements: xray?.elements.map((element) => ({
      ...element,
      onTooth: element.onTooth.map((tooth: string) => convertToothNumberFDItoUNS(tooth)),
    })),
    teeth: xray?.teeth.map((tooth) => ({
      ...tooth,
      element_type: convertToothNumberFDItoUNS(tooth.element_type),
      element_type_full: convertToothNumberFDItoUNS(tooth.element_type_full),
    })),
    retroBoneLevels: xray?.retroBoneLevels
      ? xray?.retroBoneLevels.map((level) => ({
          ...level,
          on_tooth: level.on_tooth.map((tooth) => convertToothNumberFDItoUNS(tooth)),
        }))
      : [],
    centralTeeth: xray?.centralTeeth
      ? xray?.centralTeeth.map((tooth: string) => convertToothNumberFDItoUNS(tooth))
      : null,
  };
};
