import { ElementTypeEnum, TreatmentEnum } from '@allisone/react-components';

export const IllustratedTreatmentsDataList = {
  ...TreatmentEnum,
  ...ElementTypeEnum,
  ALL_ON: 'ALL_ON',
  ALL_ON4: 'ALL_ON4',
  ALL_ON6: 'ALL_ON6',
  TEETH_BASED_BRIDGE: 'TEETH_BASED_BRIDGE',
  IMPLANT_SUPPORTED_BRIDGE: 'IMPLANT_SUPPORTED_BRIDGE',
  INLAY: 'INLAY',
  ONLAY: 'ONLAY',
  OVERLAY: 'OVERLAY',
  DENTURE_FULL: 'DENTURE_FULL',
  DENTURE_PARTIAL: 'DENTURE_PARTIAL',
  DENTURE_ACKERMAN: 'DENTURE_ACKERMAN',
  DENTURE_LOCATOR: 'DENTURE_LOCATOR',
};

const illustratedTreatmentsData = [
  {
    id: IllustratedTreatmentsDataList.BRIDGE,
    children: [
      IllustratedTreatmentsDataList.TEETH_BASED_BRIDGE,
      IllustratedTreatmentsDataList.IMPLANT_SUPPORTED_BRIDGE,
    ],
  },
  { id: IllustratedTreatmentsDataList.CROWN },
  { id: IllustratedTreatmentsDataList.ROOT_CANAL },
  { id: IllustratedTreatmentsDataList.VENEER },
  { id: IllustratedTreatmentsDataList.BONE_GRAFT },
  { id: IllustratedTreatmentsDataList.IMPLANT },
  {
    id: 'INLAY_ONLAY_OVERLAY',
    children: [
      IllustratedTreatmentsDataList.INLAY,
      IllustratedTreatmentsDataList.ONLAY,
      IllustratedTreatmentsDataList.OVERLAY,
    ],
  },
  {
    id: IllustratedTreatmentsDataList.DENTURE,
    children: [
      IllustratedTreatmentsDataList.DENTURE_FULL,
      IllustratedTreatmentsDataList.DENTURE_PARTIAL,
      IllustratedTreatmentsDataList.DENTURE_ACKERMAN,
      IllustratedTreatmentsDataList.DENTURE_LOCATOR,
    ],
  },
  { id: IllustratedTreatmentsDataList.SINUS_LIFT },
  {
    id: IllustratedTreatmentsDataList.ALL_ON,
    children: [IllustratedTreatmentsDataList.ALL_ON4, IllustratedTreatmentsDataList.ALL_ON6],
  },
  { id: IllustratedTreatmentsDataList.SCALING },
  { id: IllustratedTreatmentsDataList.ROOT_PLANING },
  { id: IllustratedTreatmentsDataList.WHITENING },
  { id: IllustratedTreatmentsDataList.POST_CORE },
  { id: IllustratedTreatmentsDataList.APICAL_RESECTION },
  { id: IllustratedTreatmentsDataList.ALIGNER },
  { id: IllustratedTreatmentsDataList.NIGHT_GUARD },
];

export { illustratedTreatmentsData };
