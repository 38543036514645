import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { anonymiseEducationalSheetFileName } from '../helpers';
import { EducationalSheetsTypes_Enum } from 'src/communication/hasura/generated/graphql';
import { methods } from 'src/controller/constants';

const UPLOAD_EDUCATIONAL_SHEET = gql`
  mutation uploadEducationSheet($input: File!) {
    file(input: $input)
      @rest(
        path: "/images/educationalSheet",
        bodySerializer: "fileEncode",
        method: ${methods.POST},
      ) {
        name
      }
  }
`;

type Return = {
  uploadEducationalSheet(params: UploadESParams): Promise<{
    fileUrl: string;
  }>;
};

type UploadESParams = {
  file: File;
  locationId: number;
  type: EducationalSheetsTypes_Enum;
};

export const useUploadEducationSheet = (): Return => {
  const [uploadEducationSheetMutation] = useMutation<{ file: { name: string } }, { input: File }>(
    UPLOAD_EDUCATIONAL_SHEET
  );

  const uploadEducationalSheet = useCallback(
    async (params: UploadESParams) => {
      const fileRename = anonymiseEducationalSheetFileName(params);
      const { data } = await uploadEducationSheetMutation({
        variables: { input: fileRename },
      });
      return { fileUrl: data?.file.name || '' };
    },
    [uploadEducationSheetMutation]
  );

  return { uploadEducationalSheet };
};
