import { GuestLayout } from '@allisone/react-components';
import { useAuth } from 'react-oidc-context';

import { ConnectedLayout } from './ConnectedLayout';
import { ModalGuard } from './ModalGuard';
import { CFC } from 'src/ui/components/FCWithChildren';
import { SelectLanguage } from 'src/ui/components/SelectLanguage';

export const Layout: CFC = ({ children }) => {
  const auth = useAuth();

  return (
    <main>
      {auth.isAuthenticated ? (
        <>
          <ModalGuard />
          <ConnectedLayout>{children}</ConnectedLayout>
        </>
      ) : (
        <GuestLayout HeaderContent={<SelectLanguage />}>{children}</GuestLayout>
      )}
    </main>
  );
};
