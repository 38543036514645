import { Anatomy } from '@allisone/react-components';
import { t } from 'i18next';

export const getTranslatedAnatomies = (anatomies: Anatomy[] | undefined): Anatomy[] => {
  if (!anatomies) return [];
  return anatomies.map((anatomy) => ({
    ...anatomy,
    type: t(`elements.${anatomy.type}`, { ns: 'elements' }),
  }));
};
