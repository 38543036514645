import { createContext, useContext } from 'react';

import type { PatientFile } from 'src/communication/hasura/query/usePatientFile';

type PatientFileValues = {
  selectConsultation(consultationId: string): void;
  refetch: PatientFile['refetch'];
};

export const PatientFileContext = createContext<PatientFileValues | null>(null);

export const usePatientFileContext = (): PatientFileValues => {
  const values = useContext(PatientFileContext);

  if (!values) {
    throw new Error(
      `${usePatientFileContext.name} can only be used in children of PatientFileContext`
    );
  }

  return values;
};
