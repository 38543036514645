import { gql, useMutation } from '@apollo/client';
import { FetchResult } from 'apollo-boost';
import { useCallback } from 'react';

const GET_PATIENT_FROM_API = gql`
  mutation GetPatientFromApi($patientid: String!) {
    getPatientFromApi(patientid: $patientid)
      @rest(path: "/v2/internal/patientPartner/{args.patientid}") {
      id
      patient
      lastConsultation
    }
  }
`;

type MutationType = {
  getPatientFromApi: {
    id: string;
    patient: {
      id: string;
      firstname: string;
      lastname: string;
      birthdate?: string;
    };
    lastConsultation: any;
  };
};

type Hook = {
  getPatientFromPartnerApi: (
    patientId: string | undefined
  ) => Promise<FetchResult<MutationType, Record<string, any>, Record<string, any>>>;
};

export const useGetPatientFromPartnerApi = (): Hook => {
  const [getPatientFromApi] = useMutation<MutationType>(GET_PATIENT_FROM_API);

  const getPatientFromPartnerApi = useCallback(async (patientId: string | undefined) => {
    if (!patientId) throw new Error('Patient ID is undefined in useGetPatientFromPartner');
    return await getPatientFromApi({
      variables: { patientid: patientId },
    });
  }, []);
  return { getPatientFromPartnerApi };
};
