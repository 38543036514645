import { GuestLayout, Loader } from '@allisone/react-components';
import * as Sentry from '@sentry/react';
import { FC, useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Outlet, useLocation } from 'react-router';

import { SelectLanguage } from '../components/SelectLanguage';
import { NewUserInformationForm } from '../pages/NewUserInformation/components/NewUserInformationForm';
import { ErrorComponent } from '../pages/NotFound/ErrorComponent';
import { Layout } from './layouts';
import { useUserQuery } from 'src/communication/hasura/generated/graphql';
import { convertToKeycloakLocale } from 'src/controller/Keycloak/keycloak';
import { getLocaleFromString } from 'src/controller/utils/app/getLocaleFromString';

export const AuthRoute: FC = () => {
  const auth = useAuth();
  const userId = (auth.user?.profile?.['https://hasura.io/jwt/claims'] as any)?.[
    'x-hasura-user-id'
  ];
  const { data, loading: userLoading } = useUserQuery({
    skip: !userId,
    variables: { userId },
  });

  const user = data?.users_by_pk;

  Sentry.setUser({ id: user?.id, email: user?.email });

  const [shouldNavigateToNewUserInformation, setShouldNavigateToNewUserInformation] =
    useState<boolean>();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [hasTriedSignout, setHasTriedSignout] = useState(false);
  const [authIsLoaded, setAuthIsLoaded] = useState(false);

  const keyCloakLocale = convertToKeycloakLocale(
    getLocaleFromString(localStorage.getItem('locale') || navigator.language)
  );

  const route = useLocation();

  useEffect(() => {
    //We wait that auth.isLoading has been set to true at least once before redirecting to login page
    if (auth.isLoading || (route.pathname === '/' && route.search === '?legacy'))
      setAuthIsLoaded(true);
  }, [auth, route]);

  useEffect(() => {
    if (userLoading || !user) return;
    if (user?.cguApprovedAt === null) {
      setShouldNavigateToNewUserInformation(true);
    } else {
      setShouldNavigateToNewUserInformation(false);
    }
  }, [userLoading, user]);

  useEffect(() => {
    if (auth.error && hasAuthParams() && !auth.isLoading && !hasTriedSignin) {
      // Error case when user bookmarked a url with old state in query params
      // We remove the query params and reload the page.
      // Cf #86bywtyuj
      window.history.replaceState({}, document.title, window.location.pathname);
      window.location.reload();
    } else if (
      // Redirect to Keycloak case
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin &&
      authIsLoaded
    ) {
      console.log('User is not authenticated, redirecting to login page');
      auth.signinRedirect({
        ui_locales: keyCloakLocale,
      });
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin, user, authIsLoaded]);

  useEffect(() => {
    if (
      !userLoading &&
      user?.locations?.length === 0 &&
      auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignout
    ) {
      console.log('User is authenticated without location, logout will occur');
      auth.signoutRedirect({
        post_logout_redirect_uri: window.location.origin,
      });
      setHasTriedSignout(true);
    }
  }, [userLoading, user?.locations?.length]);

  // hook to handle expired session and redirect to login page
  // (silent renew error is triggered when the session is expired and get token fails)
  useEffect(() => {
    return auth.events.addSilentRenewError(() => {
      if (!hasAuthParams() && !auth.activeNavigator) {
        auth.signoutRedirect({
          post_logout_redirect_uri: window.location.origin,
        });
      }
    });
  }, [auth.events, auth.signinSilent]);

  if (auth.isLoading || shouldNavigateToNewUserInformation === undefined) {
    return <Loader screenCenter />;
  }

  if (!auth.isAuthenticated) {
    return <ErrorComponent />;
  } else {
    if (shouldNavigateToNewUserInformation)
      return (
        <GuestLayout HeaderContent={<SelectLanguage />}>
          <NewUserInformationForm />
        </GuestLayout>
      );
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }
};
