import { TreatmentEnum } from 'src/ui/components/Treatment/TreatmentEnum';
import { Projection } from 'src/ui/components/XrayAnnotationTool/types';

const gums = [
  TreatmentEnum.GUM_LEFT,
  TreatmentEnum.GUM_RIGHT,
  TreatmentEnum.GUM_SOLO,
  TreatmentEnum.GUM_STANDARD,
];

const compareCrownLast = (a: Projection, b: Projection) => {
  if (gums.includes(a.elementType) && gums.includes(b.elementType)) {
    return 0;
  }
  if (gums.includes(a.elementType)) {
    return -1;
  }
  if (gums.includes(b.elementType)) {
    return 1;
  }

  return 0;
};

export const orderProjections = (projections: Projection[] = []) =>
  [...projections].sort(compareCrownLast);
