import { useToaster } from '@allisone/react-components';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useIntercom } from 'react-use-intercom';

import { useAppStore } from '../../store';
import { ConfigContextValues } from './useConfigContext';
import { AllisonePlusVersion, Locale } from 'src/common/types';
import { getTranslations } from 'src/communication/api/getTranslations';
import { useUpdateUser } from 'src/communication/hasura/query';
import { useInsertDefaultTreatmentPreferences } from 'src/controller/hooks/useInsertDefaultTreatmentPreferences';

const LANGUAGE_OPTIONS = [
  { label: 'Français', value: Locale.Fr },
  { label: 'English', value: Locale.En },
  { label: 'Español', value: Locale.Es },
  { label: 'Italiano', value: Locale.It },
  { label: 'Deutsch', value: Locale.De },
  { label: 'Português', value: Locale.Pt },
  { label: 'Català', value: Locale.Ca },
  { label: 'Svenska', value: Locale.Sv },
];

export const useConfigProvider = (): ConfigContextValues => {
  const { i18n } = useTranslation();
  const { boot, update: updateIntercom, shutdown } = useIntercom();
  const { updateUser } = useUpdateUser();
  const auth = useAuth();
  const [hasIntercomBooted, setHasIntercomBooted] = useState(false);
  const [languageOptions, setLanguageOptions] = useState(LANGUAGE_OPTIONS);
  const { show } = useToaster();

  const {
    user,
    locale,
    locationVersions,
    currentLocation,
    setLocale,
    fetchUser,
    setIsAllisonePlusV2Activated,
  } = useAppStore();

  const userId = (auth.user?.profile?.['https://hasura.io/jwt/claims'] as any)?.[
    'x-hasura-user-id'
  ];

  const changeLocale = useCallback(
    async (selectedLocale: Locale) => {
      i18n.changeLanguage(selectedLocale);
      setLocale(selectedLocale);
      if (hasIntercomBooted) updateIntercom({ languageOverride: selectedLocale });
      if (!user || !auth?.isAuthenticated) return;
      await updateUser(user.id, { locale: selectedLocale });
    },
    [user]
  );

  const setDemoCompletionDate = useCallback(async () => {
    if (!user) return;
    await updateUser(user.id, { demoMadeAt: new Date().toISOString() });
  }, [user]);

  useEffect(() => {
    // check if the user has AllisonePlusV2 activated based on the current location
    if (!user) return;
    const isAllisonePlusV2Activated = !!user.locations.find(
      ({ location }) =>
        location.allisonePlusVersion === AllisonePlusVersion.V2 && location.id === currentLocation
    );
    setIsAllisonePlusV2Activated(isAllisonePlusV2Activated);
  }, [user, currentLocation]);

  useEffect(() => {
    if (!auth?.isAuthenticated || !userId) return;
    fetchUser(userId);
  }, [userId, auth?.isAuthenticated]);

  useEffect(() => {
    if (i18n.language === locale) return;
    // 1 - if the user uses an FDA or UKCA version, set language to Locale.En
    if (locationVersions.fda || locationVersions.ukca) {
      changeLocale(Locale.En);
      return;
    }
    // 2 - By default, the language preference is stored in the local storage
    if (!!locale) {
      changeLocale(locale);
      return;
    }
    // 3 - If not, default to user language set in DB
    if (!!user?.locale) {
      changeLocale(user.locale);
      return;
    }
  }, [locale, user, locationVersions.fda, locationVersions.ukca]);

  const downloadAndSetTranslations = async (locale: string) => {
    if (!locale) return;
    try {
      const translations = (await getTranslations(locale)) as any;
      Object.keys(translations).forEach((namespace) => {
        i18n.addResourceBundle(locale, namespace, translations[namespace], true, true);
      });
    } catch (error) {
      console.log(error);
      show({
        type: 'error',
        text: 'An error occurred while downloading translations, you can try to refresh the page',
      });
    }
  };

  useEffect(() => {
    //on local change download according translations
    if (!locale) {
      downloadAndSetTranslations('en');
      return;
    }
    downloadAndSetTranslations(locale);
  }, [locale]);

  useEffect(() => {
    // Locale.En is the only available language for FDA and UKCA versions
    if (locationVersions.ukca || locationVersions.fda) {
      setLanguageOptions(languageOptions.filter(({ value }) => value === Locale.En));
    } else {
      setLanguageOptions(LANGUAGE_OPTIONS);
    }
  }, [locationVersions]);

  // Setup intercom
  useEffect(() => {
    if (!user || !user.intercomTokenId) {
      shutdown();
      setHasIntercomBooted(false);
      return;
    }

    const { email, firstname, lastname, intercomTokenId, locale } = user;
    boot({
      email,
      userId: email,
      name: `${firstname} ${lastname}`,
      userHash: intercomTokenId,
      languageOverride: locale,
    });
    setHasIntercomBooted(true);
  }, [user]);

  useInsertDefaultTreatmentPreferences();

  return {
    changeLocale,
    languageOptions,
    setDemoCompletionDate,
  };
};
