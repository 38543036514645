import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { GetConsultationDocument } from 'src/communication/hasura/generated/graphql';
import { methods } from 'src/controller/constants';

type Payload = {
  email: string[] | string;
  consultation: string;
  patientFirstname: string;
  patientLastname: string;
  doctorLastname: string;
  doctorEmail: string;
  observations: string;
  locale: string;
};

type Variables = {
  payload: Payload;
};

type Result = {
  file: {
    name: string;
    content: string;
  };
};

const SEND_CONSULTATION = gql`
  mutation SendConsultation($payload: String!) {
    sendConsultation(input: $payload)
      @rest(
        path: "/messenger/sendConsultation",
        method: ${methods.POST},
      ){
        name
      }
  }
`;

export function useSendConsultation() {
  const [sendConsultationQuery] = useMutation<Result, Variables>(SEND_CONSULTATION, {
    refetchQueries: [GetConsultationDocument],
  });
  return useCallback(async (payload: Payload) => {
    await sendConsultationQuery({
      variables: { payload },
    });
  }, []);
}
