import { AnnotationFullScreen } from '@allisone/react-components';
import { t } from 'i18next';

import { ElementFragment as Element } from 'src/common/types';
import { Element_Types } from 'src/communication/hasura/generated/graphql';
import { getDetectionBoxes } from 'src/controller/utils/basics/math';

type Param = (Pick<
  Element,
  'id' | 'coordinates' | 'detectionScore' | 'onTooth' | 'editedByUser'
> & {
  type: Pick<Element_Types, 'id' | 'name' | 'color'> & {
    parent?: Pick<Element_Types, 'id' | 'name' | 'color'> | null;
  };
})[];

export const buildAnnotations = (elements?: Param): AnnotationFullScreen[] | undefined =>
  elements?.map(({ id, coordinates, type, detectionScore, onTooth, editedByUser }) => {
    return {
      id,
      onTooth,
      path: coordinates,
      type: {
        ...type,
        name: t(type.name, { ns: 'elements' }),
        nameKey: type.name,
      },
      parentType: type.parent
        ? {
            id: type.parent.id,
            name: t(type.parent.name, { ns: 'elements' }),
            color: type.parent.color,
            nameKey: type.parent.name,
          }
        : undefined,
      detectionBoxes: getDetectionBoxes(coordinates),
      detectionScore,
      editedByUser: editedByUser ?? false,
    };
  });
