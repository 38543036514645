import { Locale } from 'src/common/types';

export const FILTERED_PATIENTS = 'FILTERED_PATIENTS';
export const CONFIRM_DELETE_MODAL = 'confirm-delete';
export const CONFIRM_PATIENT = 'confirm-patient';
export const CONFIRM_MODAL_PANO = 'confirm-modal-pano';
export const RETRY_LAZY_REFRESHED = 'retry-lazy-refreshed';

// COMPLIANCE DOCUMENTS
export const PATH_PDF_CGU = 'allisone-cgu.pdf';
export const PATH_USER_MANUAL = 'user-manual.pdf';
export const QARA_STAMPS_CE = 'QARA_stamps_CE.png';
export const QARA_STAMPS_FDA = 'QARA_stamps_FDA.png';
export const PATH_USER_MANUAL_FDA = 'IFU_FDA.pdf';
export const QARA_STAMPS_UKCA = 'QARA_stamps_UKCA.png';
export const PATH_USER_MANUAL_UKCA = 'IFU_UKCA.pdf';
export const PATH_DPA_RESELLER = 'DPA_allisone_HSO.pdf';
export const PATH_DPA_NO_RESELLER = 'DPA_allisone.pdf';

/** Method types */
export const methods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

/** Partners */
export const partnerList: string[] = [
  'desmos',
  'veasy',
  'galaxie',
  'logosw',
  'santeplus',
  'orisline',
  'weclever',
  'abaden',
  'dentalpro',
  'julie',
  'ulyses',
  'kopfwerk',
];

export type LocalPartnerConfig = {
  name: string;
  defaultDiagnosticUrl?: string;
};

export const localPartners: LocalPartnerConfig[] = [
  {
    name: 'logosw',
    defaultDiagnosticUrl: 'http://localhost:57203/patient/diagnostic',
  },
  {
    name: 'julie',
  },
];

export const imageKeyPrefix = {
  LARGE: 'radios/large/',
  MEDIUM: 'radios/medium/',
  SMALL: 'radios/small/',
};

enum TourDemoId {
  Fr = 507312,
  Es = 507477,
  It = 507479,
  En = 507480,
}

export const getIntercomDemoId = (locale: Locale) => {
  switch (locale) {
    case Locale.Fr:
      return TourDemoId.Fr;
    case Locale.Es:
      return TourDemoId.Es;
    case Locale.It:
      return TourDemoId.It;
    default:
      return TourDemoId.En;
  }
};

export const DPAResellers = ['KOPFWERK_AUSTRIA', 'ORISLINE_PORTUGAL', 'ORISLINE_ITALY'];
