import { App_Versions_Enum, UserFieldsFragment } from 'src/communication/hasura/generated/graphql';
import { isUserAllowedInVersion } from 'src/controller/utils/dentistry/isUserAllowedInVersion';

export const getVersions = (userLocations: UserFieldsFragment['locations']) => {
  const isUserFDA = isUserAllowedInVersion(userLocations, App_Versions_Enum.Fda);
  const isUserUKCA = isUserAllowedInVersion(userLocations, App_Versions_Enum.Ukca);
  const isUserCE = isUserAllowedInVersion(userLocations, App_Versions_Enum.Ce);

  /* Currently, UKCA and FDA versions are aligned, which allows us to treat users subject to either set of versions interchangeably.
  This removes the need to manage two separate sets of rules for the time being.
  This check of version is a provisory solution.
  TODO: Of UKCA and FDA versions should be monitored, and this approach should be re-evaluated if the rules diverge in the future.
  See 86bygqkvh
*/
  return {
    fda: isUserFDA,
    ukca: isUserUKCA,
    ce: isUserCE,
    commercial: !isUserCE && !isUserFDA && !isUserUKCA,
  };
};
