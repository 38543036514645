//TODO cu-86bz05pxz these url should be in env variables
export enum ImageBaseUrl {
  DEF = 'https://images.allisone.ai',
  STG = 'https://images.stg.allisone.ai',
  TEST = 'https://images.test.allisone.ai',
  DEV = 'https://images.dev.allisone.ai',
  FDA = 'https://images.fda.allisone.ai',
  CE = 'https://images.ce.allisone.ai',
}

export const getImageBaseUrl = (host: string): string => {
  if (host.includes('dev') || host.includes('127.0.0.1') || host.includes('localhost'))
    return ImageBaseUrl.DEV;
  else if (host.includes('test')) return ImageBaseUrl.TEST;
  else if (host.includes('stg')) return ImageBaseUrl.STG;
  else if (host.includes('fda')) return ImageBaseUrl.FDA;
  else if (host.includes('ce')) return ImageBaseUrl.CE;
  else return ImageBaseUrl.DEF;
};
