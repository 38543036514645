type Props = {
  file: File;
  locationId: number;
  type: string;
};

export const anonymiseEducationalSheetFileName = (props: Props): File => {
  const { file, locationId, type } = props;
  const newFileName = `${locationId}/${type}_${Date.now()}`;
  const extension = file.name.split('.').pop();
  // Add extension and replace "/" to unicode \u2215
  const fileName = `${newFileName}.${extension}`.replace(/\//g, '\u2215');

  return new File([file], fileName, {
    type: file.type,
    lastModified: file.lastModified,
  });
};
