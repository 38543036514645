import { ElementType } from '../../types';
import { ChildElementTypeEnum } from 'src/ui/components/Tooltip/types/ChildElementTypeEnum';
import { ElementTypeEnum } from 'src/ui/components/Tooltip/types/ElementTypeEnum';

export const nonPathologiesOrder: string[] = [
  `${ChildElementTypeEnum.AMALGAM_NO_NERVE_CONTACT}`,
  `${ChildElementTypeEnum.AMALGAM_NEAR_NERVE}`,
  `${ChildElementTypeEnum.AMALGAM_CONTACT_NERVE}`,
  `${ElementTypeEnum.FILLING}`,
  `${ElementTypeEnum.INLAY_ONLAY}`,
  `${ElementTypeEnum.ROOT_CANAL}`,
  `${ElementTypeEnum.POST_CORE}`,
  `${ElementTypeEnum.SCREW_POST}`,
  `${ElementTypeEnum.POST}`,
  `${ElementTypeEnum.CROWN}`,
  `${ElementTypeEnum.VENEER}`,
  `${ElementTypeEnum.BRIDGE}`,
  `${ElementTypeEnum.ROOT}`,
  `${ElementTypeEnum.IMPLANT}`,
];

export const pathologiesOrder = [
  `${ChildElementTypeEnum.DECAY_NO_NERVE_CONTACT}`,
  `${ChildElementTypeEnum.DECAY_NEAR_NERVE}`,
  `${ChildElementTypeEnum.DECAY_CONTACT_NERVE}`,
  `${ChildElementTypeEnum.ROOT_DECAY}`,
  `${ElementTypeEnum.PERIAPICAL_LESION}`,
];

export const isElementPathology = (element: ElementType): boolean =>
  element.nameKey === ChildElementTypeEnum.DECAY_CONTACT_NERVE ||
  element.nameKey === ChildElementTypeEnum.DECAY_NEAR_NERVE ||
  element.nameKey === ChildElementTypeEnum.ROOT_DECAY ||
  element.nameKey === ChildElementTypeEnum.DECAY_NO_NERVE_CONTACT ||
  element.nameKey === ElementTypeEnum.PERIAPICAL_LESION;

export const isElementHidden = (element: ElementType): boolean =>
  element.nameKey === ElementTypeEnum.ARCADE_EDENTATION ||
  element.nameKey === ElementTypeEnum.MISSING_TOOTH ||
  element.nameKey === ElementTypeEnum.PERIODONTICS;
