import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { useUpdateLastSentDateMutation } from 'src/communication/hasura/generated/graphql';
import { TreatmentFromApi } from 'src/controller/utils/partners/types';

const SEND_TREATMENT = gql`
  mutation SendTreatmentToPartner($input: InputProps) {
    treatment(input: $input)
      @rest(path: "/v2/internal/{args.input.partnerId}/treatment", method: "post") {
      created_at
      id
      treatment_items {
        canceled
        id
        operation_type
        released_at
        tooth_numbers
        pillar_teeth
      }
    }
  }
`;

interface SendDiagnosticToPartnerVariablesType {
  input: {
    partnerId: string;
    consultationId: number;
    patientId: number;
  };
}

export const useGenerateQuote = () => {
  const [sendTreatment] = useMutation<{ treatment: TreatmentFromApi }>(SEND_TREATMENT);
  const [updateLastSentDate] = useUpdateLastSentDateMutation();

  const generateQuote = useCallback(async (variables: SendDiagnosticToPartnerVariablesType) => {
    const date = new Date();
    const { data } = await sendTreatment({ variables });
    await updateLastSentDate({
      variables: {
        id: variables.input.consultationId,
        date: date.toISOString(),
      },
      refetchQueries: 'active',
    });
    return { treatment: data?.treatment };
  }, []);

  return { generateQuote };
};
