import { TreatmentEnum } from '@allisone/react-components';

import { TreatmentPreferences } from '../types';
import { MissingTeethOperationEnum } from './MissingTeethOperationEnum';
import { PathologiesEnum } from './PathologiesEnum';

export const template2: TreatmentPreferences = {
  preferences: {
    pathologies: [
      {
        name: PathologiesEnum.DECAY_NO_NERVE_CONTACT,
        options: [
          {
            id: '',
            dentalSchemaCategory: 'all',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.INLAY_ONLAY,
              },
            ],
          },
          {
            id: '',
            dentalSchemaCategory: 'all',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.FILLING,
              },
            ],
          },
        ],
      },
      {
        name: PathologiesEnum.DECAY_NEAR_NERVE,
        options: [
          {
            id: '',
            dentalSchemaCategory: 'all',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.INLAY_ONLAY,
              },
              {
                id: '',
                label: '',
                value: TreatmentEnum.PULP_CAPPING,
              },
            ],
          },
          {
            id: '',
            dentalSchemaCategory: 'all',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.FILLING,
              },
              {
                id: '',
                label: '',
                value: TreatmentEnum.PULP_CAPPING,
              },
            ],
          },
        ],
      },
      {
        name: PathologiesEnum.DECAY_CONTACT_NERVE,
        options: [
          {
            id: '',
            dentalSchemaCategory: 'all except wisdom teeth',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.ROOT_CANAL_RETREATMENT,
              },
              {
                id: '',
                label: '',
                value: TreatmentEnum.POST_CORE,
              },
              {
                id: '',
                label: '',
                value: TreatmentEnum.CROWN,
              },
            ],
          },
          {
            id: '',
            dentalSchemaCategory: 'only wisdom teeth',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.EXTRACTION,
              },
            ],
          },
        ],
      },
      {
        name: PathologiesEnum.ROOT_DECAY,
        options: [
          {
            id: '',
            dentalSchemaCategory: 'all',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.EXTRACTION,
              },
            ],
          },
        ],
      },
      {
        name: PathologiesEnum.PERIAPICAL_LESION,
        options: [
          {
            id: '',
            dentalSchemaCategory: 'all except wisdom teeth',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.ROOT_CANAL_RETREATMENT,
              },
              {
                id: '',
                label: '',
                value: TreatmentEnum.POST_CORE,
              },
              {
                id: '',
                label: '',
                value: TreatmentEnum.CROWN,
              },
            ],
          },
          {
            id: '',
            dentalSchemaCategory: 'only wisdom teeth',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.EXTRACTION,
              },
            ],
          },
        ],
      },
      {
        name: PathologiesEnum.ROOT,
        options: [
          {
            id: '',
            dentalSchemaCategory: 'all except wisdom teeth',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.EXTRACTION,
              },
              {
                id: '',
                label: '',
                value: TreatmentEnum.IMPLANT,
              },
              {
                id: '',
                label: '',
                value: TreatmentEnum.CROWN,
              },
            ],
          },
          {
            id: '',
            dentalSchemaCategory: 'only wisdom teeth',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.EXTRACTION,
              },
            ],
          },
        ],
      },
      {
        name: PathologiesEnum.AMALGAM_NO_NERVE_CONTACT,
        options: [
          {
            id: '',
            dentalSchemaCategory: 'all',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.INLAY_ONLAY,
              },
            ],
          },
          {
            id: '',
            dentalSchemaCategory: 'all',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.FILLING,
              },
            ],
          },
        ],
      },
      {
        name: PathologiesEnum.AMALGAM_NEAR_NERVE,
        options: [
          {
            id: '',
            dentalSchemaCategory: 'all',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.INLAY_ONLAY,
              },
              {
                id: '',
                label: '',
                value: TreatmentEnum.PULP_CAPPING,
              },
            ],
          },
          {
            id: '',
            dentalSchemaCategory: 'all',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.FILLING,
              },
              {
                id: '',
                label: '',
                value: TreatmentEnum.PULP_CAPPING,
              },
            ],
          },
        ],
      },
      {
        name: PathologiesEnum.AMALGAM_CONTACT_NERVE,
        options: [
          {
            id: '',
            dentalSchemaCategory: 'all',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.EXTRACTION,
              },
            ],
          },
        ],
      },
    ],
    missingTeeth: [
      {
        name: MissingTeethOperationEnum.FULL_ARCH_EDENTULISM,
        options: [
          {
            id: '',
            dentalSchemaCategory: 'all',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.DENTURE,
              },
            ],
          },
        ],
      },
      {
        name: MissingTeethOperationEnum.MISSING_ADJACENT_TEETH_ONE_TO_TWO,
        options: [
          {
            id: '',
            dentalSchemaCategory: 'all except wisdom teeth',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.IMPLANT,
              },
              {
                id: '',
                label: '',
                value: TreatmentEnum.CROWN,
              },
            ],
          },
          {
            id: '',
            dentalSchemaCategory: 'all except wisdom teeth',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.BRIDGE,
              },
            ],
          },
        ],
      },
      {
        name: MissingTeethOperationEnum.MISSING_ADJACENT_TEETH_THREE_TO_FOUR,
        options: [
          {
            id: '',
            dentalSchemaCategory: 'all except wisdom teeth',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.IMPLANT,
              },
              {
                id: '',
                label: '',
                value: TreatmentEnum.CROWN,
              },
            ],
          },
          {
            id: '',
            dentalSchemaCategory: 'all except wisdom teeth',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.BRIDGE,
              },
            ],
          },
        ],
      },
      {
        name: MissingTeethOperationEnum.FREE_POSTERIOR_EDENTULISM,
        options: [
          {
            id: '',
            dentalSchemaCategory: 'all except wisdom teeth',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.IMPLANT,
              },
              {
                id: '',
                label: '',
                value: TreatmentEnum.BRIDGE,
              },
            ],
          },
          {
            id: '',
            dentalSchemaCategory: 'all except wisdom teeth',
            treatments: [
              {
                id: '',
                label: '',
                value: TreatmentEnum.DENTURE,
              },
            ],
          },
        ],
      },
    ],
  },
  extraRules: {
    extractionIsMissingTooth: true,
    noBridgeWithHealthyPillarTeeth: true,
    noBridgeOnWisdomTeeth: true,
    noInlayOnlayOnIncisiveAndCanine: true,
    sinusLiftOnlyOnMaxilloMolar: true,
    rootCanalOnlyIfUntreatedTooth: true,
  },
  priorities: [
    {
      id: 8,
      pathologyName: PathologiesEnum.ROOT,
    },
    {
      id: 3,
      pathologyName: PathologiesEnum.ROOT_DECAY,
    },
    {
      id: 7,
      pathologyName: PathologiesEnum.PERIAPICAL_LESION,
    },
    {
      id: 4,
      pathologyName: PathologiesEnum.DECAY_CONTACT_NERVE,
    },
    {
      id: 0,
      pathologyName: PathologiesEnum.AMALGAM_CONTACT_NERVE,
    },
    {
      id: 6,
      pathologyName: PathologiesEnum.DECAY_NEAR_NERVE,
    },
    {
      id: 2,
      pathologyName: PathologiesEnum.AMALGAM_NEAR_NERVE,
    },
    {
      id: 5,
      pathologyName: PathologiesEnum.DECAY_NO_NERVE_CONTACT,
    },
    {
      id: 1,
      pathologyName: PathologiesEnum.AMALGAM_NO_NERVE_CONTACT,
    },
  ],
};
