import * as Sentry from '@sentry/react';
import axios from 'axios';

//Fetch translations from Locise API
export const getTranslations = async (
  language: string,
  namespaces: string[] = []
): Promise<object> => {
  try {
    const { data } = await axios.post('/api/v2/internal/locize/translations', {
      language,
      namespaces,
    });
    const { translationKeys } = data;
    return translationKeys;
  } catch (error) {
    Sentry.captureException(error, { extra: { url: '/api/v2/internal/locize/translations' } });
    throw new Error(error as any);
  }
};
